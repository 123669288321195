import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button, InputGroup, Breadcrumb, Table, Spinner } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHome, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield, faAngleDown, faEye } from "@fortawesome/free-solid-svg-icons";
import Parse from '../vendor/Parse'
import { useLocation } from '@reach/router';
import ProgressBar from './Preloader'
import AdBanner from '../components/Ad'
const { useState } = React;

const IndexPage = ({ items, onItem, isLoading, cols, renderItem }) => {


    return isLoading ? <div className="text-center p-2"><Spinner animation="border" variant="primary" /></div> : <Table>
        <thead className="thead-light" >
            <tr>
                {
                    cols?.map(e => <th className="border-0 align-middle"> {e.label} </th>)
                }

            </tr>
        </thead >
        <tbody>
            {
                items?.map((item, key) => {


                    
                    return <>
                        {
                            key === 3 && <tr  className="border-0  align-middle" colspan={cols.length}><td><AdBanner /></td></tr>
                        }
                        <tr key={key}>

                        {
                            cols?.map(e => <td className="border-0  align-middle">
                                {
                                    e.onRender ? e.onRender(item) : item[e.key]
                                }
                            </td>)
                        }
                    </tr></>

                })
            }
        </tbody>
    </Table >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
